<template>
    <div class="row">
        <div class="col-12" >
            <g-loading v-if="firstLoading" />
            <g-table v-else :filter="filterFormModel" :itemCount="itemCount" :items="items" :fields="fields" @load="load" @reset="reset"
                     :title="`${client.name} thiết lập chỉ tiêu`" titleIcon="fa fa-dot-circle">
                <template #cell(year)="{item}">
                    <span v-if="item.year != -1" >Năm {{item.year}}</span>
                    <span v-else >Toàn dự án</span>
                </template>

                <template #cell(time)="{item}">
                    <div>{{item.updated_time * 1000 | datetimeFormat}}</div>
                </template>

                <template #cell(items)="{item}">
                    <div v-if='params.items[item.id]' >
                        <div v-for="(val, code) in params.items[item.id]" :key="code" class="row" >
                            <div class="col-9">
                                <i>- {{indicators[code] ? indicators[code] : code}}</i>: <span class='text-danger'>{{val.toFixed(2).replace(/\.0+$/,'')}}</span>
                            </div>
                        </div>
                    </div>
                    <div v-else >
                        <span class="small text-gray-999">Chưa thiết lập</span> 
                    </div>
                </template>
            </g-table>
        </div>
    </div>
</template>

<script>
    import list from "@/mixins/List";

    export default {
        props: ["id"],
        components: {},
        mixins: [list],
        data: function () {
            return {
                autoLoad: false,
                reloadBus: "reloadKpi",
                service: '/kpi',
                fields: [
                    {key: 'year', label: "Thời gian"},
                    {key: 'items', label: "Chỉ tiêu"},
                    {key: 'time', label: "Cập nhật lúc", class: 'text-center'},
                ],
                client: this.id ? null : this.$store.state.client,
                defaultFilterFormModel: {
                    parent_id: this.id ? this.id : this.$store.state.client.id,
                    client_id: this.$store.state.client.id,
                    quarter: null,
                    year: null
                },
                indicators: {}
            };
        },
        watch: {
            id: {
                handler() {
                    this.setClient();
                }, deep: true
            }
        },
        methods: {
            setClient() {
                let id = this.id ? this.id : this.$store.state.client.id;
                this.defaultFilterFormModel.parent_id = id;
                this.filterFormModel.parent_id = id;

                this.$service.get(`/client/get?id=${id}`).then(response => {
                    this.client = response.data;
                    this.load();
                });
            }
        },
        mounted: function () {
            this.indicators = {};
            for (let code in this.$params.indicator) {
                let indicator = this.$params.indicator[code];
                this.indicators[code] = indicator.name;
            }

            this.setClient();
        }
    }
</script>